<template>
  <d-remote-monitoring-questionnaires
    @on-select-item="onSelectItemHandler"
    @on-go-to-add-page="goToAddHandler"
  />
</template>

<script>

export default {
  name: 'RemoteMonitoringQuestionnaires',
  page: {
    title: 'CRM Doctis - Анкеты ДМ',
  },
  methods: {
    onSelectItemHandler(id) {
      this.$router.push(`/remote-monitoring/questionnaires/view/${id}/`);
    },
    goToAddHandler() {
      this.$router.push('/remote-monitoring/questionnaires/add/');
    },
  },
};
</script>
